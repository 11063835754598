var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wrapper" }, [
    _c("div", { staticClass: "image-title" }, [_vm._v("Crop & Resize")]),
    _c("div", { staticClass: "image-title small" }, [
      _vm._v("Scroll to zoom in and out"),
    ]),
    _c(
      "div",
      { staticClass: "image" },
      [
        _c("VueCropper", {
          ref: "cropper",
          attrs: {
            img: this.image,
            autoCrop: true,
            canScale: true,
            fixed: true,
            infoTrue: true,
            full: true,
            fixedNumber: _vm.maxDimensions,
            outputType: "png",
          },
          on: { realTime: _vm.handleRealTime },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }