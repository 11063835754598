<template>
  <div>
    <div class="wrapper">
      <input
        style="display: none;"
        ref="input"
        type="file"
        class="input"
        @change="handleInputChange"
      >
      <div class="button-container">
        <button class="button" @click.stop.prevent="handleButtonClick">Select Image File</button>
      </div>
      <Resize :image="imageBase64" @crop="handleCrop" v-if="imageBase64" :imageInfo="imageInfo"/>
            <Resize :image="uploadBase64" v-else/>
    </div>
  </div>
</template>

<script>
import UploadForm from "components/UploadForm";
import Resize from "components/imagemanager/image_resizer.vue";
import uploadImg from "img/upload.png";

export default {
    name: "ImageLoader",

    components: {
        UploadForm,
        Resize
    },
    props: {
        imageInfo: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            uploadBase64: uploadImg,
            imageBase64: false,
            fileName: false,
            uploaded: false,
            imageHeight: false,
            imageWidth: false,
        };
    },
    mounted() {
        this.handleButtonClick()
    },
    computed: {

    },

    methods: {
        handleFormUpload(e, { fileName, base64 }) {
            this.imageBase64 = base64;
            this.fileName = fileName;
        },

        handleCrop(data) {
            if (!this.uploaded) return
            this.$emit('crop', {fileName: this.fileName, imageBase64: data})
        },
        handleButtonClick() {
            const { input } = this.$refs;

            input.click();
        },

        handleInputChange(e) {
            const { value } = e.target;
            if (value && value !== "") {
                this.handleUpload(e)
            }
        },

        handleUpload(e) {
            const [file] = e.target.files;

            if (!/\.(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(e.target.value)) {
                alert("Invalid format");

                return false;
            }

            const reader = new FileReader();

            reader.addEventListener("load", e => {
                const data =
          typeof e.target.result === "object"
              ? window.URL.createObjectURL(new Blob([e.target.result]))
              : e.target.result;

                this.imageBase64 = e.target.result,
                this.fileName = file.name
                this.uploaded = true


            });

            reader.readAsDataURL(file);
        }
    }
};
</script>

<style lang="scss" scoped>
@import "~scss/variables";
@import "~scss/mixins";

.wrapper {
  min-height: 400px;
  padding-top: 20px;
  padding-top: 20px;
}

.button-container,
.divider {
  text-align: center;
}

.divider {
  font-size: 30px;
  line-height: 30px;
}

.button {
  display: inline-block;
  padding: 10px 40px;
  border: 0;
  border-radius: 40px;
  background-color: $brand-color;
  color: #fff;
  transition: background-color 300ms;
  outline: none;

  &:hover {
    background-color: lighten($brand_color, 5%);
  }
}
</style>

