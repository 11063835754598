<template>
  <div class="wrapper">
    <div class="image-title">Crop & Resize</div>
    <div class="image-title small">Scroll to zoom in and out</div>
    <div class="image">
      <VueCropper
        ref="cropper"
        :img="this.image"
        :autoCrop="true"
        :canScale="true"
        :fixed="true"
        :infoTrue="true"
        :full="true"
        :fixedNumber="maxDimensions"
        outputType="png"
        @realTime="handleRealTime"
      />
    </div>
  </div>
</template>

<script>
import { VueCropper } from "vue-cropper";

export default {
    name: "ImageResizer",

    components: {
        VueCropper
    },

    props: {
        image: {
            // Base64
            type: String,
            required: true
        },
        imageInfo: {
            type: Object,
            required: false
        }
    },
    computed: {
        maxDimensions() {
            if (this.imageInfo == undefined) return []
            return [this.imageInfo.maxDimensions.width, this.imageInfo.maxDimensions.height]
        }
    },
    methods: {
        handleRealTime(data) {
            this.$refs.cropper.getCropData(data => {
                this.$emit("crop", data);
            });
        }
    }
};
</script>

<style lang="scss" scoped>
@import "~scss/variables";
@import "~scss/mixins";

.image-title {
  margin-bottom: 20px;
  text-align: center;
  font-size: 20px;
  line-height: 20px;
  font-weight: bold;
}

.image {
  height: 500px;
}
</style>

