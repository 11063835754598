var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "wrapper" },
      [
        _c("input", {
          ref: "input",
          staticClass: "input",
          staticStyle: { display: "none" },
          attrs: { type: "file" },
          on: { change: _vm.handleInputChange },
        }),
        _c("div", { staticClass: "button-container" }, [
          _c(
            "button",
            {
              staticClass: "button",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.handleButtonClick.apply(null, arguments)
                },
              },
            },
            [_vm._v("Select Image File")]
          ),
        ]),
        _vm.imageBase64
          ? _c("Resize", {
              attrs: { image: _vm.imageBase64, imageInfo: _vm.imageInfo },
              on: { crop: _vm.handleCrop },
            })
          : _c("Resize", { attrs: { image: _vm.uploadBase64 } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }